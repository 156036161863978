<template>
  <section class="m-check-phone m-scroll">

    <!-- 头部导航 -->
    <m-header title="绑定邮箱"></m-header>

    <p class="title">绑定邮箱</p>

    <div class="input-cell">
      <input v-model="email" type="text" placeholder="输入邮箱">
    </div>



    <div class="input-cell">
      <input v-model="code" type="text" placeholder="请输入验证码">
      <div class="get-code">
        <span v-show="!getCode" @click="onSend" class="get">获取验证码</span>
        <span v-show="getCode"  class="seconds">{{seconds}}S</span>
      </div>
    </div>
  
    <div class="footer">
      <van-button @click="onNext" round block type="info" native-type="submit" color="linear-gradient(315deg,rgba(240,60,24,1) 0%,rgba(240,100,72,1) 100%)">确定</van-button>
    </div>








    

  </section>
</template>

<script>
import MHeader  from '@/components/zh/m-header.vue'
import { bindEmail } from '@/api/zh/mine.js'
import { email_code } from '@/api/zh/login'
export default {
  name:'BindEmail',
  components:{ MHeader },
  data(){
    return {
      email:'',
      code:'',
      getCode:false,
      seconds:60,
    }
  },

  

  methods:{

    onSend(){
      let params ={}
      // eslint-disable-next-line 
      let reg = new RegExp(/^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/)
      if(this.email == '' || !reg.test(this.email) ){
        this.$notify({ type: 'warning', message: '请输入正确的邮箱'})
        return false
      }else {
        params.email = this.email
      }
      this.$store.commit('load')
      email_code(params).then(res => {
        if(res) {
          this.$notify({ type: 'success', message: '发送成功' });
          this.getCode = true
          const timer = setInterval(() => {
            if(this.seconds < 1){
              this.getCode = false
              this.seconds = 60
              clearInterval(timer)
            }
            this.seconds -- 
          }, 1000); 
        }
      }).finally( () => {
       this.$store.commit('unload')
      })


      
    },
    onNext(){
      
      let params = {}

      if(this.email == ''){
        this.$notify({ type: 'warning', message: '请完善邮箱'})
        return false
      }else {
        params.email = this.email
      }

      if(this.code == ''){
        this.$notify({ type: 'warning', message: '请输入验证码'})
        return false
      }else {
        params.code = this.code
      }

      bindEmail(params).then(res => {
        if(res) {
          this.$notify({ type: 'success', message: '邮箱绑定成功'})
          if(this.$route.query.flag == 1) {
            this.$router.replace(`/zh/order/0?back=account`)
          } else {
            this.$router.go(-1)
          }
        } 
      })


      
    },

  },

  created() {
    
  }



}
</script>

<style lang="less" scoped>
.m-check-phone {
  width: 100%;
  height: 100%;
  background-color: #F5F9FF;
  padding-top: 44px;
  .title {
    margin: 30px 0 10px;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    padding-left: 20px;
  }
  .input-cell {
    background: #fff;
    padding:0 20px;
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-rows: 52px;
    >input {
      outline: none;
      border: none;
      font-size: 14px;
    }
    .phone-type {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      >span {
        font-size:14px;
        font-weight:400;
        color:rgba(51,51,51,1);
        line-height:20px;
      }
      >img {
        width: 20px;
        height: 20px;
        transform:rotate(90deg) ;
        margin-left: 5px;
      }
    }
    .get-code {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      span {
        padding: 5px 8px;
        border: 1px solid #3284FA;
        color: #3284FA;
        font-size: 14px;
        font-weight: 400;
        border-radius: 5px;
      }
    }
  }
  .footer {
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 38px;
  }
}
</style>